.tableBad {
    background-color: #842029 !important;
    color: #ffffff !important;
}

.tableBad td {
    background-color: #842029 !important;
    color: #ffffff !important;
}

.tableHighlight {
    background-color: #ffff00 !important;
    color: #000000 !important;
}

.tableHighlight td {
    background-color: #ffff00 !important;
    color: #000000 !important;
}

.patTable {
    width: 100%;
    display: table;
    border: 0;
    border-collapse: collapse;
}

.patTable th {
    border-bottom: 2px solid #2b2b2b;
    color: #6d6d6d;
    padding: 0.75rem 1rem;
    text-align: left;
    margin: 0;
}

.patTable td {
    border-bottom: 1px solid #2b2b2b;
    color: #6d6d6d;
    padding: 0.75rem 1rem;
    text-align: left;
}

.patTable tbody tr:hover {
    background-color: rgb(235, 246, 255);
    cursor: pointer;
}

.ant-table-content .patSubTable{
    border-bottom: none !important;
}


.labelMute {
    color: #6d6d6d;
}

.labelSubText {
    font-size: 0.8rem;
}


.reviewFees .css-1uhpu4c .ant-table .ant-table-container,
.searchResults .css-1uhpu4c .ant-table .ant-table-container {
    overflow: visible;
}

.localspinner {
    width: 20px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: rgb(35, 114, 184);
    --_m:
            conic-gradient(#0000 10%,#000),
            linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}

.flex-container {
    display: flex;
}

.flex-container-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.fcr-valign-center {
    align-items: center;
}

.flex-container-row .fcr-item {
    padding: 10px;
}

.fakeLinkDefault {
    color: #000000;
    text-decoration: underline;
    cursor: pointer;
}

.softbox {
    width: 250px;
    height: 80px;
    background-color: #4a4a4a;
    border-radius: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: #FFFFFF;
}

.softboxWhite {
    background-color: #FFFFFF;
    border-radius: 5%;
    color: #000000;
    border: black 1px solid;
}

.softboxRed {
    background-color: #85100A;
    border-radius: 5%;
    color: #FFFFFF;
    border: black 1px solid;
}

.boldLabel {
    font-weight: bold;
}
.label20 {
    font-size: 2rem;
}

.adjustForGridLabel {
    padding-top: 20px;
}

.searchResultsBulkAction {
    justify-content: flex-end;
    padding-right: 15px;
}
.srBulkActionItem {
    padding: 5px;
}

.ids-menu-container button {
    padding: 10px;
}

.feeSelectCheckbox {
    transform: scale(1.5); /* Adjust the scale value as needed */
    -webkit-transform: scale(1.5); /* For Safari */
    -moz-transform: scale(1.5); /* For Firefox */
    -ms-transform: scale(1.5); /* For IE */
    -o-transform: scale(1.5); /* For Opera */
    margin: 5px; /* Optiona*/
}

/*simple table styles*/
.patHtmlTable {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 0.9rem;
}

.patHtmlTable thead {
    border-bottom: 2px solid #aaa4a4;
}

.patHtmlTable thead th {
    font-size: 0.7rem;
    text-align: left;
    padding-right: 25px;
}

.patHtmlTable td {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 25px;
    text-align: left;
}

.patHtmlTable tbody tr {
    border-bottom: 1px solid #aaa4a4;
    transition: background-color 0.3s ease;
}

.patHtmlTable tbody tr:hover {
    background-color: rgb(235, 246, 255); /* Change this color to your desired hover color */
}
/*end simple table styles*/

.bocRateDetail td {
    padding: 0px;
}